import React from "react";
import { Box, Typography } from "@mui/material";

const GifDisplay = () => {
  return (
    <Box
      alignItems="center"
      justifyContent="center"
      sx={{ textAlign: "center", width: "1000px", margin: "0 auto" }}
    >
      <Box
        component="img"
        src="../../../addcomment.gif" // Adjust path based on where you store the GIF
        alt="Animated GIF"
        sx={{ maxWidth: "100%", height: "auto" }}
      />
    </Box>
  );
};

export default GifDisplay;
