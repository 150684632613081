import React, { useEffect } from "react";
import AppAppBar from "./AppAppBar";
import { AppAppBarProps } from "./AppAppBar";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { Box, Button, Container, Typography } from "@mui/material";
import { fetchAuthSession } from "aws-amplify/auth";
import "@aws-amplify/ui-react/styles.css";
import { Hub } from "@aws-amplify/core";
import { useNavigate } from "react-router-dom";
const userSelector = (context: any) => [context.user];
export default function Login({ mode, toggleColorMode }: AppAppBarProps) {
  const navigate = useNavigate();
  const SignOutButton = () => {
    const { user, signOut } = useAuthenticator(userSelector);

    const handleSignOut = async () => {
      // Call signOut function from useAuthenticator

      signOut();
    };

    return (
      <Button
        onClick={handleSignOut}
        variant="contained"
        color="primary"
        sx={{
          alignSelf: "center",
          backgroundColor: "black",
          color: "white",
        }}
      >
        Hello, Click here to sign out!
      </Button>
    );
  };
  useEffect(() => {
    async function checkCredentials() {
      try {
        console.log("running checkCredentials from appbard");
        const { tokens } = await fetchAuthSession();
        if (tokens !== undefined) {
          navigate("/");
        }
      } catch (e) {
        console.log(e);
      }
    }

    checkCredentials();
  }, []);

  return (
    <>
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Authenticator>
          {({ signOut, user }) => (
            <Container>
              <Typography variant="h4" align="center" gutterBottom>
                Hello
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <SignOutButton />
              </Box>
            </Container>
          )}
        </Authenticator>
      </Box>
    </>
  );
}
