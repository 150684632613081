import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormHelperText,
} from "@mui/material";
import Paper from "@mui/material/Paper";
const tiers = [
  {
    title: "Novice Pack",
    price: "1",
    description: [
      "Get started with our AI-powered platform! The Novice Pack offers $1 worth of credits, perfect for new users to explore and experience the basics of AI-generated comments. Ideal for light usage and trying out our features.",
    ],
    buttonText: "Start now",
    buttonVariant: "outlined",
    link: process.env.REACT_APP_PACKAGE1 || "",
  },
  {
    title: "Journeyman Pack",
    subheader: "Recommended",
    price: "5",
    description: [
      "Unlock more potential with the Journeyman Pack! For $5, you receive a substantial amount of credits to interact more extensively with our AI and generate insightful comments. This pack is great for users who need moderate usage for professional or personal projects.",
    ],
    buttonText: "Start now",
    buttonVariant: "contained",
    link: process.env.REACT_APP_PACKAGE2 || "",
  },
  {
    title: "Genius Pack",
    price: "10",
    description: [
      "Maximize your interaction with the Genius Pack! Purchase $10 worth of credits to fully leverage our AI capabilities for generating high-quality comments. Designed for heavy users who require extensive AI interaction for their advanced projects.",
    ],

    buttonText: "Start now",
    buttonVariant: "outlined",
    link: process.env.REACT_APP_PACKAGE3 || "",
  },
];

function createData(
  modelname: string,
  inputpricingPerToken: number,
  outputpricingPerToken: number
) {
  //(element.costPerInputTokenClient * 1000).toString()
  return {
    name: modelname,
    inputpricing: `$${inputpricingPerToken}`,
    outputpricing: `$${outputpricingPerToken}`,
  };
}
/*
 createData(
    "Claude",
    "$0.06 per 1,000 input tokens",
    "$0.3 per 1,000 output tokens"
  ),
  createData(
    "More models on the way...",
    "$0.xx per 1,000 input tokens",
    "$0.xx per 1,000 output tokens"
  ),*/
interface RowData {
  modelname: string;
  inputpricing: string;
  outputpricing: string;
}
interface PriceTableProps {
  rows: RowData[];
}
function PriceTable({ rows }: PriceTableProps) {
  if (rows.length === 0) return null;
  return (
    <>
      <Typography component="h2" variant="h4" color="text.primary">
        How Pricing Works
      </Typography>
      <Typography component="h2" variant="h6">
        Straightforward and flexible. Pay only for what you use, and nothing
        more.
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Models
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Price per 1,000 input tokens
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                Price per 1,000 output tokens
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left" component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.inputpricing}</TableCell>
                <TableCell align="left">{row.outputpricing}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FormHelperText>
        Prices are subject to change without prior notice.
      </FormHelperText>
    </>
  );
}
const apiEndpointGetPricing = process.env.REACT_APP_UNPROTECTED || "";
console.log("apiEndpointGetPricing", apiEndpointGetPricing);
const todo = {
  operation: "getPricing",
};
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export default function Pricing() {
  const [rows, setRows] = React.useState<[]>([]);

  const navigate = useNavigate();
  React.useEffect(() => {
    const getPricing = async () => {
      try {
        const response = await fetch(apiEndpointGetPricing, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(todo),
        });
        const responseData = await response.json();
        const pricingData: any = [];
        responseData.forEach((element: any) => {
          pricingData.push(
            createData(
              element.modelName,
              element.costPerInputTokenClient * 1000,
              element.costPerOutputTokenClient * 1000
            )
          );
        });
        setRows(pricingData);
        console.log("pricingData: ", pricingData);
        console.log("pricing: ", responseData);
        console.log("rows: ", rows);
      } catch (e) {
        console.log("unable to get pricing: ", e);
      }
    };
    getPricing();
  }, []);

  const goToShoppingCart = async (link: string) => {
    console.log(link);
    const { tokens } = await fetchAuthSession();
    const isLoggedIn = tokens !== undefined;
    if (isLoggedIn) {
      const { signInDetails } = await getCurrentUser();
      const email = signInDetails?.loginId;
      console.log("signinDetails ", signInDetails);
      window.location.href = `${link}?prefilled_email=${email}`;
    } else {
      navigate("/signin");
    }
  };

  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Pricing
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Each package is designed to offer a seamless experience for users
          looking to enhance their commenting capabilities. Select the package
          that best fits your needs and start generating comments today. <br />
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                border: tier.title === "Professional" ? "1px solid" : undefined,
                borderColor:
                  tier.title === "Professional" ? "primary.main" : undefined,
                background:
                  tier.title === "Professional"
                    ? "linear-gradient(#033363, #021F3B)"
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: tier.title === "Professional" ? "grey.100" : "",
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === "Professional" && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === "light" ? "" : "none",
                        backgroundColor: "primary.contrastText",
                        "& .MuiChip-label": {
                          color: "primary.dark",
                        },
                        "& .MuiChip-icon": {
                          color: "primary.dark",
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    color:
                      tier.title === "Professional" ? "grey.50" : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    ${tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp;
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: "grey.500",
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === "Professional"
                            ? "grey.200"
                            : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant as "outlined" | "contained"}
                  component="a"
                  onClick={() => goToShoppingCart(tier.link)}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <PriceTable rows={rows} />
      <Typography component="h2" variant="h6">
        Stay tuned for more models coming soon, offering you even more options
        to craft the perfect comment or reply.
      </Typography>
    </Container>
  );
}
