import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/system";

const whiteLogos = [
  "/static/images/logos/yt_logo_rgb_light.png",
  /*"/static/images/logos/Bern-white.svg",
  "/static/images/logos/Montreal-white.svg",
  "/static/images/logos/TerraLight.svg",
  "/static/images/logos/colorado-white.svg",
  "/static/images/logos/Ankara-white.svg",*/
];

const darkLogos = [
  "/static/images/logos/yt_logo_rgb_light.png",
  /*"/static/images/logos/Bern-white.svg",
  "/static/images/logos/Montreal-white.svg",
  "/static/images/logos/TerraLight.svg",
  "/static/images/logos/colorado-white.svg",
  "/static/images/logos/Ankara-white.svg",*/
];

const logoStyle = {
  width: "100px",
  height: "auto", // Set height to auto to maintain aspect ratio
  margin: "0 32px",
  opacity: 0.7,
};

export default function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === "light" ? darkLogos : whiteLogos;

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="h2"
        variant="h4"
        align="center"
        color="text.secondary"
      >
        Social Media Sites currently supported. More on the way soon...
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 0.5, opacity: 0.6 }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`Fake company number ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
