const awsConfig = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_t8HuUoTjd",
  aws_user_pools_web_client_id: "tg94q11cmcehe1rdrr861nbdh",
  aws_cloud_logic_custom: [
    {
      name: "AICommentBuddy-api-prod", // This is the API name you should use in your code
      endpoint: "https://7650baew8f.execute-api.us-east-1.amazonaws.com/prod",
      region: "us-east-1",
    },
  ],
  oauth: {
    domain: "aicomment-ai-prod",
    scope: ["email", "openid", "profile"],
    redirectSignIn: "http://localhost:3000/",
    redirectSignOut: "http://localhost:3000/",
    responseType: "code", // or 'token', 'id_token', 'code id_token', 'code token', 'code id_token token'
  },
};

export default awsConfig;
