import * as React from "react";
import PropTypes from "prop-types";
import awsConfig from "./aws-exports-manully";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import AppAppBar from "./components/AppAppBar";
import Hero from "./components/Hero";
import LogoCollection from "./components/LogoCollection";
import Highlights from "./components/Highlights";
import Pricing from "./components/Pricing";
import Features from "./components/Features";
import Testimonials from "./components/Testimonials";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import getLPTheme from "./getLPTheme";
import { PaletteMode } from "@mui/material";
import ImageSlider from "./components/ImageSlider";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Login from "./components/Login";
import { Amplify } from "aws-amplify";
import YouTubeVideo from "./components/YouTubeVideo";
import GifDisplay from "./components/GifDisplay";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUsePolicy from "./components/TermsOfUse";
Amplify.configure(awsConfig);
function ToggleCustomTheme({
  showCustomTheme,
  toggleCustomTheme,
}: {
  showCustomTheme: any;
  toggleCustomTheme: any;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100dvw",
        position: "fixed",
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: "background.default",
          "& .Mui-selected": {
            pointerEvents: "none",
          },
        }}
      >
        {/*}
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: "20px", mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design 2</ToggleButton> */}
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default function LandingPage() {
  const mode = "light" as PaletteMode;
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    //setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };
  const Home = () => {
    return (
      <>
        <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
        <Hero />
        <Box sx={{ bgcolor: "background.default" }}>
          <GifDisplay />
          <Divider />
          <LogoCollection />
          <Divider />
          <Pricing />
          <Divider />
          <Footer />
        </Box>
      </>
    );
  };
  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/signin"
            element={<Login mode={mode} toggleColorMode={toggleColorMode} />}
          />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsOfUse" element={<TermsOfUsePolicy />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
