const awsConfig = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_kKO1Udj0j",
  aws_user_pools_web_client_id: "500mc518eim9bmtp81hoeotffg",
  aws_cloud_logic_custom: [
    {
      name: "AICommentBuddy-api-dev", // This is the API name you should use in your code
      endpoint: "https://flypm0zr95.execute-api.us-east-1.amazonaws.com/dev",
      region: "us-east-1",
    },
  ],
  oauth: {
    domain: "aicomment-ai-dev",
    scope: ["email", "openid", "profile"],
    redirectSignIn: "http://localhost:3000/",
    redirectSignOut: "http://localhost:3000/",
    responseType: "code", // or 'token', 'id_token', 'code id_token', 'code token', 'code id_token token'
  },
};

export default awsConfig;
