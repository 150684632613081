import awsConfigDev from "./aws-exports.dev";
import awsConfigQA from "./aws-exports.qa";
import awsConfigProd from "./aws-exports.prod";

export interface AWSCloudLogicCustomAIBuddy {
  name: string;
  endpoint: string;
  region: string;
}

export interface AWSConfigOAuthAIBuddy {
  domain: string;
  scope: string[];
  redirectSignIn: string;
  redirectSignOut: string;
  responseType: string;
}

export interface AWSConfigAIBuddy {
  aws_project_region: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  aws_cloud_logic_custom: AWSCloudLogicCustomAIBuddy[];
  oauth: AWSConfigOAuthAIBuddy;
}
console.log("urls manually environement", process.env.REACT_APP_ENVVARIABLE);
const environement = process.env.REACT_APP_ENVVARIABLE;

let awsConfig: Urls;

if (environement === "prod") {
  awsConfig = awsConfigProd;
} else if (environement === "qa") {
  awsConfig = awsConfigQA;
} else {
  awsConfig = awsConfigDev;
}

export default awsConfig;
