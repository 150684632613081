import * as React from "react";
import { PaletteMode, Popper } from "@mui/material";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ToggleColorMode from "./ToggleColorMode";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { fetchAuthSession } from "aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import LogoutIcon from "@mui/icons-material/Logout"; // Optional: using an icon
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import {
  signIn,
  signOut,
  getCurrentUser,
  signUp,
  confirmSignUp,
} from "@aws-amplify/auth";
import useGetBalance from "./hooks/GetBalance";
const logoStyle = {
  width: "75px",
  height: "auto",
  cursor: "pointer",
};

export interface AppAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

function AppAppBar({ mode, toggleColorMode }: AppAppBarProps) {
  const [balance] = useGetBalance();
  const userSelector = (context: any) => [context.user];
  const [open, setOpen] = React.useState(false);
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  const [showProfilediv, setShowProfilediv] = React.useState(false);
  const navigate = useNavigate();
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  useEffect(() => {
    console.log("set up listern in app bar");
    const listener = (data: any) => {
      const { event } = data.payload;
      //console.log("auth event: ", event);
      switch (event) {
        case "signedIn":
          setIsSignedIn(true);
          console.log("User signed in");
          navigate("/");
          break;
        case "signedOut":
          setIsSignedIn(false);
          console.log("User signed out");
          break;
        case "signIn_failure":
          console.log("User sign in failed");
          break;
        default:
          console.log("default User sign in case");
          break;
      }
    };

    const unsubscribe = Hub.listen("auth", listener);

    // Cleanup listener on component unmount
    return () => {
      console.log("unsubscribe auth listern");
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    async function checkCredentials() {
      try {
        console.log("running checkCredentials from appbard");
        const { tokens } = await fetchAuthSession();
        setIsSignedIn(tokens !== undefined);
        console.log("tokens: ", tokens);
      } catch (e) {
        console.log(e);
      }
    }

    checkCredentials();
  }, []);

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  function SimplePopper() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;
    const HandleSignOut = async () => {
      await signOut();
    };
    return (
      <div>
        <Button variant="contained" onClick={handleClick}>
          {" "}
          <AccountCircleIcon />
        </Button>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            modifiers={[
              {
                name: "zIndex",
                enabled: true,
                phase: "beforeWrite",
                fn: ({ state }) => {
                  state.styles.popper.zIndex = "1300";
                },
              },
            ]}
          >
            <Box
              sx={{
                border: 1,
                p: 1,
                bgcolor: "background.paper",
                borderRadius: "8px",
                width: { xs: "100%", sm: "200px" }, // Full width on extra-small screens, 200px on small and up
                maxWidth: "200px", // Ensures it doesn't exceed 200px
              }}
            >
              Balance: {balance}
              <Divider />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<LogoutIcon />} // Optional: add a logout icon
                onClick={HandleSignOut}
              >
                Logout
              </Button>
            </Box>
          </Popper>
        </ClickAwayListener>
      </div>
    );
  }

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                ml: "-18px",
                px: 0,
              }}
            >
              <img
                onClick={() => navigate("/")}
                src="../../../logo.png" // Adjust path based on where you store the GIF
                style={logoStyle}
                alt="logo of sitemark"
              />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <MenuItem
                  onClick={() => scrollToSection("highlights")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    Highlights
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("pricing")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    Pricing
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 0.5,
                alignItems: "center",
              }}
            >
              {isSignedIn ? (
                <SimplePopper />
              ) : (
                <>
                  <Button
                    onClick={() => navigate("/signin")}
                    color="primary"
                    variant="contained"
                    size="small"
                    component="a"
                  >
                    Sign in/ Sign up
                  </Button>
                </>
              )}
            </Box>
            <Box sx={{ display: { sm: "", md: "none" } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: "30px", p: "4px" }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      flexGrow: 1,
                    }}
                  >
                    <ToggleColorMode
                      mode={mode}
                      toggleColorMode={toggleColorMode}
                    />
                  </Box>
                  <MenuItem onClick={() => scrollToSection("features")}>
                    Features
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("testimonials")}>
                    Testimonials
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("highlights")}>
                    Highlights
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("pricing")}>
                    Pricing
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("faq")}>
                    FAQ
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="contained"
                      component="a"
                      href="/material-ui/getting-started/templates/sign-up/"
                      target="_blank"
                      sx={{ width: "100%" }}
                    >
                      Sign up
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      component="a"
                      href="/material-ui/getting-started/templates/sign-in/"
                      target="_blank"
                      sx={{ width: "100%" }}
                    >
                      Sign in
                    </Button>
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppAppBar;
