import React, { useEffect, useState } from "react";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { Balance } from "@mui/icons-material";

export default function useGetBalance() {
  const [balance, setBalance] = useState(0);
  console.log("balance:", balance);
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const { signInDetails } = await getCurrentUser();
        console.log("signInDetails from getBaLANCE", signInDetails);
        const { tokens } = await fetchAuthSession();

        const todo = {
          operation: "coinBalance",
          username: { username: signInDetails?.loginId },
        };
        const apiEndpoint = process.env.REACT_APP_PROTECTED || "";
        console.log("tokens from useGetBalance:", tokens);
        const response = await fetch(apiEndpoint, {
          method: "POST", // Use "GET", "PUT", "DELETE", etc., as needed
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokens?.idToken?.toString()}`,
          },
          body: JSON.stringify(todo),
        });
        const responseData = await response.json();
        console.log("API response coin balance:", responseData);
        setBalance(responseData.balanceFormatted);
      } catch (e) {
        setBalance(0);
        console.log("error getting balanc ", e);
      }
    };

    fetchBalance();
  }, []);

  return [balance];
}
